import React from 'react';
import { motion } from 'framer-motion';
import './Projects.css';
import 'react-tooltip/dist/react-tooltip.css';
import { Link } from 'react-router-dom';


import github from '../Assets/github.svg';
import discord from '../Assets/discord.svg';
import browse from '../Assets/browse.svg';


const Projects = () => {


  return (
    <div className="projects-main">
      <motion.section
        id="projects"
        className="projects-section"
        initial={{ opacity: 0, scale: 0.95 }}
        whileInView={{ opacity: 1, scale: 1 }}
        viewport={{ once: true, amount: 0.1 }}
        transition={{ duration: 0.5 }}
      >

<h1 className="projectsheader2">Some Projects</h1>


<div className="project-flex">
  
        {/* Project 1 - Food Fest Game */}
        <div className="project-card card">
        <div className="project1">
            
            <div className='projectstext'>
            <h1 className='projectheader'>Food Fest Game</h1>
            <div className="project-tags">
  <span className="label fun">Fun</span>
  <span className="label engaging">Engaging</span>
  <span className="label compatible">Compatible</span>
  <span className="label interactive">Interactive</span>
</div>


              A fun and interactive React-based game developed for a university food festival. The game lets players compete by scoring points in a Doodle Jump-style game. Firebase backend ensures real-time scoring and leaderboards.

            </div>
            <div className="button-container">
  <a
    href="https://github.com/nisalherath/foodfestgame"
    target="_blank"
    rel="noopener noreferrer"
    className="githubbtn githubbtn1"
    aria-label="View Source Code on GitHub"
    style={{ textDecoration: 'none' }} 
  >
    <img src={github} className="githubimg" alt="GitHub Logo" />
    Source Code
  </a>
  
  <a
    href="https://foodfestgame.netlify.app/"
    target="_blank"
    rel="noopener noreferrer"
    className="githubbtn githubbtn1"
    aria-label="Play Food Fest Game"
    style={{ textDecoration: 'none' }} 
  >
    <img src={browse} className="githubimg" alt="Play Game" />
    Play The Game
  </a>
</div>

        </div>
        </div>


       <div className="project-card card">
        {/* Project 2 - Nerdcake Discord Bot */}
        <div className="project2">

            <div className='projectstext'>
            <h1 className='projectheader'>Nerdcake Bot</h1>
            <div className="project-tags">
  <span className="label versatile">Versatile</span>
  <span className="label useful">Useful</span>
  <span className="label fast">Fast</span>
  <span className="label messages">Messages</span>
  <span className="label reliable">Reliable</span>
  <span className="label tag24-7">24-7 / 365</span>
</div>


            A multipurpose Discord bot developed with Python. It automates tasks like fetching random images, providing game deals, delivering positive quotes, and running speed tests to enhance user experience in Discord servers.
</div>
<div className="button-container">
  <a
    href="https://discord.com/oauth2/authorize?client_id=1288790078826479646"
    target="_blank"
    rel="noopener noreferrer"
    className="githubbtn githubbtn1"
    aria-label="Try it on Discord"
    style={{ textDecoration: 'none' }}  // Remove text decoration
  >
    <img src={discord} className="githubimg" alt="Discord" />
    Try it on Discord
  </a>

  <Link
  to="/nerdcake"
  className="githubbtn githubbtn1"
  aria-label="Read More about Nerdcake"
>
  <img
    src={browse}
    className="githubimg"
    alt="Navigate to Nerdcake details"
    onError={(e) => (e.target.style.display = 'none')}
  />
  Read More
</Link>

</div>
        </div>
        </div>



      <div className="project-card card">
        {/* Project 3 - Car Rental App */}
        <div className="project3">

            <div className='projectstext'>
            <h1 className='projectheader'>Car Rental App</h1>
            <div className="project-tags">
  <span className="label minimalui">Minimal UI</span>
  <span className="label userfriendly">User-friendly</span>
  <span className="label efficient">Efficient</span>
  <span className="label multitask">Multi-Task</span>
  <span className="label reliable">Reliable</span>
</div>


      A full-fledged React-based rental management application with a Java Springboot backend. It allows users to easily view available vehicles, rent them quickly, and efficiently manage their rental periods. </div>
      <div className="button-container">
  <a
    href="https://github.com/nisalherath/RentalApp.git"
    target="_blank"
    rel="noopener noreferrer"
    className="githubbtn githubbtn1"
    aria-label="View Source Code on GitHub"
    style={{ textDecoration: 'none' }}  // Remove text decoration
  >
    <img src={github} className="githubimg" alt="GitHub" />
    Source Code
  </a>
  
  <a
    href="https://rental101.netlify.app/"
    target="_blank"
    rel="noopener noreferrer"
    className="githubbtn githubbtn1"
    aria-label="Try the Rental App"
    style={{ textDecoration: 'none' }}  // Remove text decoration
  >
    <img src={browse} className="githubimg" alt="Browse App" />
    Try the App
  </a>
</div>

        </div>

        </div>




      <div className="project-card card">
        {/* Project 4 - Social Media Bot */}
        <div className="project4">

            <div className='projectstext'>
            <h1 className='projectheader'>Social Media Bot</h1>
            <div className="project-tags">
  <span className="label versatile">Versatile</span>
  <span className="label useful">Useful</span>
  <span className="label fast">Fast</span>
  <span className="label messages">Messages</span>
  <span className="label reliable">Reliable</span>
  <span className="label tag24-7">24-7 / 365</span>
</div>


            A Python-based bot that automates social media posts by generating a random Sinhala word, turning it into an image, and posting it to Facebook, Instagram, and Twitter every hour.
            </div>
            <div className="button-container">
  <a
    href="https://web.facebook.com/RandomSinhalaWord"
    target="_blank"
    rel="noopener noreferrer"
    className="githubbtn githubbtn1"
    aria-label="Visit on Facebook"
    style={{ textDecoration: 'none' }}  // Remove text decoration
  >
    <img src={browse} className="githubimg" alt="Facebook" />
    On Facebook
  </a>

  <a
    href="https://www.instagram.com/sinhala333/"
    target="_blank"
    rel="noopener noreferrer"
    className="githubbtn githubbtn1"
    aria-label="Visit on Instagram"
    style={{ textDecoration: 'none' }}  // Remove text decoration
  >
    <img src={browse} className="githubimg" alt="Instagram" />
    On Instagram
  </a>

  <a
    href="https://x.com/sinhala333"
    target="_blank"
    rel="noopener noreferrer"
    className="githubbtn githubbtn1"
    aria-label="Visit on Twitter"
    style={{ textDecoration: 'none' }}  // Remove text decoration
  >
    <img src={browse} className="githubimg" alt="Twitter" />
    On Twitter
  </a>
</div>

        </div>
        </div>






        <div className="project-card card">
        {/* Project 5 - Deep Learning - Cat Detection */}
        <div className="project4">

            <div className='projectstext'>
            <h1 className='projectheader'>Deep Learning - Cat Detection</h1>
            <div className="project-tags">
  <span className="label versatile">Automation</span>
  <span className="label useful">Machine Learning</span>
  <span className="label fast">Fast</span>
  <span className="label messages">Detection</span>
  <span className="label reliable">YOLOv5</span>
  <span className="label tag24-7">ResNet50</span>
</div>


This Python project uses AI-powered image recognition to accurately detect and classify cats in real-time, automating the process with high precision.</div>
            <div className="button-container">
            <a
    href="https://github.com/nisalherath/Kitty-Detect"
    target="_blank"
    rel="noopener noreferrer"
    className="githubbtn githubbtn1"
    aria-label="View Source Code on GitHub"
    style={{ textDecoration: 'none' }}  // Remove text decoration
  >
    <img src={github} className="githubimg" alt="GitHub" />
    Source Code
  </a>

  <Link
  to="/kitty-detect"
  className="githubbtn githubbtn1"
  aria-label="Read More about Nerdcake"
>
  <img
    src={browse}
    className="githubimg"
    alt="Navigate to Nerdcake details"
    onError={(e) => (e.target.style.display = 'none')}
  />
  Read More
</Link>
</div>

        </div>
        </div>


        </div>
      </motion.section>
    </div>
  );
};

export default Projects;
