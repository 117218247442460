import React, { memo, useRef, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { EffectCoverflow, Autoplay } from 'swiper/modules';

const UnsplashCarousel = memo(({ images }) => {
  const progressCircle = useRef(null);
  const progressContent = useRef(null);

  const onAutoplayTimeLeft = (swiper, time, progress) => {
    if (progressCircle.current) {
      progressCircle.current.style.setProperty('--progress', 1 - progress);
    }
    if (progressContent.current) {
      progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
    }
  };

  // Force autoplay
  useEffect(() => {
    const swiperInstance = document.querySelector('.mySwiper').swiper;
    if (swiperInstance) {

      swiperInstance.autoplay.start();
    }
  }, []);

  return (
    <>
      <Swiper
        effect="coverflow"
        grabCursor={true}
        centeredSlides={true}
        slidesPerView="auto"
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: false,
        }}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
          pauseOnMouseEnter: false,
          waitForTransition: false,
          enabled: true,
        }}
        touchRatio={1}
        simulateTouch={true}
        modules={[EffectCoverflow, Autoplay]}
        className="mySwiper"
        onAutoplayTimeLeft={onAutoplayTimeLeft}
        style={{
          width: '100%',
          minWidth: '300px',
          height: '100%',
          outline: 'none',
        }}
      >
        {images.map((img, index) => (
          <SwiperSlide key={index} style={{ outline: 'none' }}>
            <div
              style={{
                position: 'relative',
                width: '100%',
                height: '250px',
                overflow: 'hidden',
                borderRadius: '0px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src={
                  img?.urls?.regular
                    ? `${img?.urls?.regular}&w=1280`
                    : 'https://res.cloudinary.com/dlnhogbjy/image/upload/v1726349905/cld-sample-2.jpg'
                }
                alt={img?.alt_description || `Slide ${index + 1}`}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: '0px',
                  outline: 'none',
                }}
                loading="lazy"
              />
              <p
                className="legend"
                style={{
                  fontSize: '12px',
                  color: '#fff',
                  textAlign: 'center',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  borderRadius: '0px',
                  padding: '5px 10px',
                  position: 'absolute',
                  bottom: '10px',
                  left: '50%',
                  transform: 'translateX(-50%)',
                }}
              >
                {img?.alt_description || 'No description'}
              </p>
            </div>
          </SwiperSlide>
        ))}

        <div className="autoplay-progress" slot="container-end">
          <svg viewBox="0 0 48 48" ref={progressCircle}>
            <circle cx="24" cy="24" r="20"></circle>
          </svg>
          <span ref={progressContent} className="pagecounter"></span>
        </div>
      </Swiper>
    </>
  );
}, (prevProps, nextProps) => {
  return JSON.stringify(prevProps.images) === JSON.stringify(nextProps.images);
});

export default UnsplashCarousel;
