import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTopButton = () => {
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const location = useLocation();

  const hiddenRoutes = [
    "/nerdcake",
    "/advice",
    "/kitty-detect",
    "/astro",
    "/qr-generator",
    "/wallpaper-gallery",
    "*"
  ];

  const checkScrollPosition = () => {
    const isAtBottom =
      document.documentElement.scrollHeight -
        document.documentElement.scrollTop -
        window.innerHeight <
      50;
    setShowScrollToTop(isAtBottom);
  };

  useEffect(() => {
    // Check scroll position when scrolling
    window.addEventListener("scroll", checkScrollPosition);

    return () => window.removeEventListener("scroll", checkScrollPosition);
  }, []);

  useEffect(() => {
    checkScrollPosition();
  }, [location]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  if (hiddenRoutes.includes(location.pathname)) return null;

  return (
    showScrollToTop && (
      <button
        className="scroll-to-top"
        onClick={scrollToTop}
        aria-label="Scroll to top of the page"
      >
        ⬆ Scroll to Top
      </button>
    )
  );
};

export default ScrollToTopButton;
