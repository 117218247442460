import React, { useEffect, useRef } from 'react';
import Swal from 'sweetalert2';
import { Helmet } from 'react-helmet';
import Navbar from '../Navbar/Navbar';
import About from '../About/About';
import Experience from '../Experience/Experience';
import Skills from '../Skills/Skills';
import Projects from '../Projects/Projects';
import Footer from '../Footer/Footer';
import Fun from '../Fun/Fun';
import './HomePage.css';
import 'animate.css';

const HomePage = () => {
  const sectionsRef = useRef({});

  useEffect(() => {
    const expirationTime = 60 * 60 * 1000; // 1 Hour
    const currentTime = new Date().getTime();
    const blogNotificationData = JSON.parse(localStorage.getItem("blogNotificationData"));
  
    if (!blogNotificationData || currentTime - blogNotificationData.timestamp > expirationTime) {
      Swal.fire({
        title: "Check out my latest blog!",
        text: "Stay updated with my latest posts and insights.",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Take me there",
        cancelButtonText: "Later",
        customClass: {
          backdrop: 'alert-backdrop',
          popup: 'alert',
          confirmButton: 'alert-btn',
          cancelButton: 'alert-btn',
        },
        focusConfirm: false,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = "/blog";
        } else {
          localStorage.setItem(
            "blogNotificationData",
            JSON.stringify({ token: "true", timestamp: currentTime })
          );
        }
      });
    }
  }, []);
  

  // Manage intersection observer for URL hash updates
  useEffect(() => {
    const sections = document.querySelectorAll('section');
    sections.forEach((section) => {
      sectionsRef.current[section.id] = section;
    });

    let lastUrlUpdate = Date.now();
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const now = Date.now();
            if (now - lastUrlUpdate > 300) { // Throttle hash updates
              const sectionId = entry.target.id;
              window.history.pushState(null, '', `#${sectionId}`);
              lastUrlUpdate = now;
            }
          }
        });
      },
      {
        root: null,
        threshold: 0,
        rootMargin: '0px 0px -20% 0px',
      }
    );

    sections.forEach((section) => observer.observe(section));

    return () => {
      sections.forEach((section) => observer.unobserve(section));
    };
  }, []);

  return (
    <div>
      {/* Helmet for SEO */}
      <Helmet>
        <title>Nisal Herath - Home</title>
        <meta name="description" content="Web site created by Nisal Herath, showcasing projects and portfolio." />
        <meta name="keywords" content="Nisal Herath, portfolio, web development, automation, React, Python, developer, SLIIT, Sri Lanka" />
        <meta name="author" content="Nisal Herath" />
        <meta property="og:title" content="Nisal Herath - Developer & Automation Specialist" />
        <meta property="og:description" content="Explore the personal website of Nisal Herath, a developer specializing in web applications and automation." />
        <meta property="og:image" content="https://web.facebook.com/photo?fbid=1576640353092218&set=a.112999039456364" />
        <meta property="og:url" content="https://nisal.lk" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Nisal Herath - Developer & Automation Specialist" />
        <meta name="twitter:description" content="Explore the personal website of Nisal Herath, a developer specializing in web applications and automation." />
        <meta name="twitter:image" content="https://x.com/ArtNisal/photo" />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <Navbar />
      <main>
        <section id="about">
          <About />
        </section>
        <section id="experience">
          <Experience />
        </section>
        <section id="skills">
          <Skills />
        </section>
        <section id="projects">
          <Projects />
        </section>
        <section id="fun">
          <Fun />
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default HomePage;
