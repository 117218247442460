import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import LenisSetup from './Lenis/LenisSetup';
import { ThemeProvider } from "./Theme/Theme";
import ScrollToTop from './ScrollToTop/ScrollToTop';
import HomePage from './Components/HomePage/HomePage';
import NotFound from './Components/NotFound/Notfound';
import CSStips from './Components/CSStips/CSStips';
import Nerdcake from './Components/Nerdcake/Nerdcake';
import Advice from './Components/Advice/Advice';
import Astro from './Components/Astro/Astro';
import QrGen from './Components/QrGen/QrGen';
import WallGallery from './Components/WallGallery/WallGallery';
import KittyDetect from './Components/KittyDetect/KittyDetect';
import Blog from './Components/Blog/Blog';
import Blog1 from './Components/Blog/BlogPages/Blog1';
import './App.css';

const validSections = ['about', 'experience', 'skills', 'projects', 'fun'];

const useScrollToSection = () => {
  const location = useLocation();

  useEffect(() => {
    const sectionId = location.hash.substring(1).toLowerCase();

    if (sectionId && validSections.includes(sectionId)) {
      const targetElement = document.getElementById(sectionId);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    } else if (sectionId) {
      window.location.href = '/404';
    }
  }, [location]);
};

function App() {
  return (
    
      <Router>
        <ThemeProvider>
        <LenisSetup>
        <ScrollHandler />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/css" element={<CSStips />} />
          <Route path="/nerdcake" element={<Nerdcake />} />
          <Route path="/advice" element={<Advice />} />
          <Route path="/astro" element={<Astro />} />
          <Route path="/qr-generator" element={<QrGen />} />
          <Route path="/wallpaper-gallery" element={<WallGallery />} />
          <Route path="/kitty-detect" element={<KittyDetect />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/1" element={<Blog1 />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <ScrollToTop /> 
        </LenisSetup>
        </ThemeProvider>
      </Router>
  );
}

function ScrollHandler() {
  const location = useLocation();

  useEffect(() => {
    if (!location.hash) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  useScrollToSection();

  return null;
}

export default App;
