import React, { memo, useRef, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { EffectCoverflow, Autoplay } from 'swiper/modules';

const NFTcarousel = memo(({ pictures }) => {
  const progressCircle = useRef(null);
  const progressContent = useRef(null);

  const onAutoplayTimeLeft = (swiper, time, progress) => {
    if (progressCircle.current) {
      progressCircle.current.style.setProperty('--progress', 1 - progress);
    }
    if (progressContent.current) {
      progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
    }
  };

  useEffect(() => {
    const swiperInstance = document.querySelector('.mySwiper').swiper;
    if (swiperInstance) {
      swiperInstance.autoplay.start();
    }
  }, []);

  return (
    <>
      <Swiper
        effect="coverflow"
        grabCursor={true}
        centeredSlides={true}
        slidesPerView="auto"
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: false,
        }}
        loop={true}
        autoplay={{
          delay: 1500,
          disableOnInteraction: false,
        }}
        modules={[EffectCoverflow, Autoplay]}
        touchRatio={1}
        simulateTouch={true}
        className="mySwiper"
        onAutoplayTimeLeft={onAutoplayTimeLeft}
        style={{
          width: '300px',
          height: '300px',
          outline: 'none',
          borderRadius: '15px',
        }}
      >
        {pictures.map((img, index) => (
          <SwiperSlide key={index} style={{ outline: 'none' }}>
            <div
              style={{
                position: 'relative',
                width: '100%',
                height: '100%',
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                loading="lazy"
                src={img.src || 'https://res.cloudinary.com/dlnhogbjy/image/upload/v1734563806/cow2_wwz9pl.webp'}
                alt={img.alt || `Slide ${index + 1}`}
                style={{
                  width: '100%',
                  height: 'auto',
                  objectFit: 'cover',
                  borderRadius: '15px',
                  outline: 'none',
                }}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="autoplay-progress2" slot="container-end">
        <svg viewBox="0 0 48 48" ref={progressCircle}>
          <circle cx="24" cy="24" r="20"></circle>
        </svg>
        <span ref={progressContent} className="pagecounter"></span>
      </div>
    </>
  );
}, (prevProps, nextProps) => {

  return JSON.stringify(prevProps.pictures) === JSON.stringify(nextProps.pictures);
});

export default NFTcarousel;
