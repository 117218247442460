import React, { useEffect, useState} from "react";
import { motion, AnimatePresence } from "framer-motion";

import Footer from "./../../Footer/Footer";

import ShapeTool from "./../../Assets/SVG/ShapeTool";
import PenSwing from "./../../Assets/SVG/PenSwing";
import Windmill from "./../../Assets/SVG/Windmil";

import "./Blog1.css";

import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-coverflow";

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "react-tooltip/dist/react-tooltip.css";
import "react-circular-progressbar/dist/styles.css";

import browse from "./../../Assets/browse.svg";

const illustratorImage =
  "https://res.cloudinary.com/dlnhogbjy/image/upload/v1739013616/illustrator_ss_kiv4zj.webp";

const proarts = [
  {
    className: "pic1",
    src: "https://res.cloudinary.com/dlnhogbjy/image/upload/v1734563562/Milky_ysozae.webp",
  },
  {
    className: "pic2",
    src: "https://res.cloudinary.com/dlnhogbjy/image/upload/v1734563517/Christmas_2022_o0naoa.webp",
  },
  {
    className: "pic3",
    src: "https://res.cloudinary.com/dlnhogbjy/image/upload/v1734563543/GOT_fa06f5.webp",
  },
  {
    className: "pic4",
    src: "https://res.cloudinary.com/dlnhogbjy/image/upload/v1734563553/Cricket_thzzxm.webp",
  },
  {
    className: "pic5",
    src: "https://res.cloudinary.com/dlnhogbjy/image/upload/v1734563602/Icey_night_km1nlu.webp",
  },
  {
    className: "pic6",
    src: "https://res.cloudinary.com/dlnhogbjy/image/upload/v1734563533/Christmas_2021_xri6zw.webp",
  },
];

const layers = [
  {
    className: "pic1",
    src: "https://res.cloudinary.com/dlnhogbjy/image/upload/v1738972474/layer1_xfkplc.webp",
  },
  {
    className: "pic2",
    src: "https://res.cloudinary.com/dlnhogbjy/image/upload/v1738972475/layer2_k93ypa.webp",
  },
  {
    className: "pic3",
    src: "https://res.cloudinary.com/dlnhogbjy/image/upload/v1738972475/layer3_cbjeqs.webp",
  },
  {
    className: "pic4",
    src: "https://res.cloudinary.com/dlnhogbjy/image/upload/v1738972475/layer4_asmaqy.webp",
  },
  {
    className: "pic5",
    src: "https://res.cloudinary.com/dlnhogbjy/image/upload/v1738972475/layer5_obe668.webp",
  },
  {
    className: "pic6",
    src: "https://res.cloudinary.com/dlnhogbjy/image/upload/v1738972475/layer6_xxppsd.webp",
  },
];

const layer1christmas =
  "https://res.cloudinary.com/dlnhogbjy/image/upload/v1739161467/christmas-layer1_hb7hgu.webp";
const layer2christmas =
  "https://res.cloudinary.com/dlnhogbjy/image/upload/v1739161467/christmas-layer2_fqi4em.webp";
const layer3christmas =
  "https://res.cloudinary.com/dlnhogbjy/image/upload/v1739161467/christmas-layer3_zguem0.webp";
const layer4christmas =
  "https://res.cloudinary.com/dlnhogbjy/image/upload/v1739161468/christmas-layer4_n4oiwy.webp";
const layer5christmas =
  "https://res.cloudinary.com/dlnhogbjy/image/upload/v1739161467/christmas-layer5_dcafsr.webp";

const Blog1 = () => {
  const [key, setKey] = useState(0);

  useEffect(() => {
    const totalDuration = 12;
    const interval = setInterval(() => {
      setKey((prevKey) => prevKey + 1);
    }, totalDuration * 1000);
  
    return () => clearInterval(interval);
  }, []);




  const slideInFromLeft = {
    hidden: { x: -50, opacity: 0 },
    visible: { x: 0, opacity: 1 },
  };

  const slideInFromRight = {
    hidden: { x: 50, opacity: 0 },
    visible: { x: 0, opacity: 1 },
  };

  const fadeVariant = {
    hidden: { y: 10, opacity: 0 },
    visible: { y: 0, opacity: 1 },
  };

  return (
    <section id="blog1" className="blog1-main">
      <Helmet>
        <title>Nisal Herath - ProArt-Blog </title>
        <meta
          name="description"
          content="Web site created by Nisal Herath, showcasing projects and portfolio."
        />
        <meta
          name="keywords"
          content="Nisal Herath, portfolio, web development, automation, React, Python, developer, SLIIT, Sri Lanka"
        />
        <meta name="author" content="Nisal Herath" />
        <meta
          property="og:title"
          content="Nisal Herath - Developer & Automation Specialist"
        />
        <meta
          property="og:description"
          content="Explore the personal website of Nisal Herath, a developer specializing in web applications and automation."
        />
        <meta
          property="og:image"
          content="https://web.facebook.com/photo?fbid=1576640353092218&set=a.112999039456364"
        />
        <meta property="og:url" content="https://nisal.lk" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Nisal Herath - Developer & Automation Specialist"
        />
        <meta
          name="twitter:description"
          content="Explore the personal website of Nisal Herath, a developer specializing in web applications and automation."
        />
        <meta name="twitter:image" content="https://x.com/ArtNisal/photo" />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <div className="blog1-component">
        <div className="blog1">
          <Link
            to="/blog"
            className="backhome55"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <div className="arrowhead2">
              <div className="arrowline12"></div>
              <div className="arrowline22"></div>
              <div className="arrowmainline2"></div>
            </div>
            Back to Blog
          </Link>

          <div className="blog1card">

          <motion.section
          className="blog1-appear"
        initial={{ opacity: 0, y: -50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, amount: 0.1 }}
        transition={{ duration: 0.6 }}
              > 
            <div className="blog1-item item1">
              <h1>
                {" "}
                <Windmill /> What Are Pro Arts?{" "}
              </h1>
            </div>

            <div className="blog1-item item2">
              <p>
                Vector Based designes that has multiple layers of objects laying
                on top of each other. These Art can be hyper realistic or be
                drawn in a way of silhouette Art. Mainly I master in doing
                silhouette style of design, where I take a noticeable character
                and make that into a recognizable silhouette. These can include,
                Video-Game characters / Movie Characters / Popular Figures or
                Animals.
              </p>

              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
                variants={slideInFromRight}
                transition={{ duration: 1 }}
              >
                <Swiper
                  effect="coverflow"
                  grabCursor={true}
                  centeredSlides={true}
                  slidesPerView="auto"
                  coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: false,
                  }}
                  loop={true}
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                  }}
                  modules={[EffectCoverflow, Autoplay]}
                  touchRatio={1}
                  simulateTouch={true}
                  className="proart-swiper"
                  style={{
                    width: "350px",
                    height: "350px",
                    outline: "none",
                    borderRadius: "15px",
                  }}
                >
                  {proarts.map((img, index) => (
                    <SwiperSlide key={index} style={{ outline: "none" }}>
                      <div
                        style={{
                          position: "relative",
                          width: "100%",
                          height: "100%",
                          overflow: "hidden",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          loading="lazy"
                          src={
                            img.src ||
                            "https://res.cloudinary.com/dlnhogbjy/image/upload/v1734563562/Milky_ysozae.webp"
                          }
                          alt={img.alt || `Slide ${index + 1}`}
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </motion.div>
            </div>
            </motion.section>


            <motion.section
          className="blog1-appear"
        initial={{ opacity: 0, y: -50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, amount: 0.1 }}
        transition={{ duration: 0.6 }}
              > 
            <div className="blog1-item item3">
              <p>
                If you are interested in my work, You can find me on Instagram
                or Behance. Right down Below ⬇️
              </p>
              <div className="button-container">
                <a
                  href="https://www.instagram.com/mooncaque/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="githubbtn githubbtn1"
                  aria-label="Visit on Instagram"
                  style={{ textDecoration: "none" }}
                >
                  <img src={browse} className="githubimg" alt="Instagram" />
                  On Instagram
                </a>

                <a
                  href="https://www.behance.net/nisalherath"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="githubbtn githubbtn1"
                  aria-label="Visit on Behance"
                  style={{ textDecoration: "none" }}
                >
                  <img src={browse} className="githubimg" alt="Behance" />
                  On Behance
                </a>
              </div>
            </div>
            </motion.section>


            
            <div className="blog1-item item4">
              <h1>
                <Windmill /> Reason why Pro Art Stand Out?
              </h1>
            </div>

            <div className="blog1-item item5">
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
                variants={slideInFromLeft}
                transition={{ duration: 1 }}
              >
                <Swiper
                  effect="coverflow"
                  grabCursor={true}
                  centeredSlides={true}
                  slidesPerView="auto"
                  coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: false,
                  }}
                  loop={true}
                  autoplay={{
                    delay: 1000,
                    disableOnInteraction: false,
                    reverseDirection: true,
                  }}
                  modules={[EffectCoverflow, Autoplay]}
                  touchRatio={1}
                  simulateTouch={true}
                  className="proart-swiper"
                  style={{
                    width: "350px",
                    height: "350px",
                    outline: "none",
                    borderRadius: "15px",
                  }}
                >
                  {layers
                    .slice()
                    .reverse()
                    .map((img, index) => (
                      <SwiperSlide key={index} style={{ outline: "none" }}>
                        <div
                          style={{
                            position: "relative",
                            width: "100%",
                            height: "100%",
                            overflow: "hidden",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            loading="lazy"
                            src={
                              img.src ||
                              "https://res.cloudinary.com/dlnhogbjy/image/upload/v1738972475/layer6_xxppsd.webp"
                            }
                            alt={img.alt || `Slide ${index + 1}`}
                          />
                        </div>
                      </SwiperSlide>
                    ))}
                </Swiper>
              </motion.div>

              <p>
                From Layers being semi-transparent gives the ArtWork a depth
                effect and allows the person to really look Deep into Art. As
                you can See in this picture, you are constructing the Background
                layers in a way that it has both Colour & Transparency.
              </p>
            </div>

            <div className="blog1-item item4">
              <h1>
                <Windmill /> Breaking Down the Technique
              </h1>
            </div>

            <div className="blog1-item item4">
              <h2>* Using Adobe Illustrator</h2>
            </div>

            <div className="blog1-item item6">
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
                variants={slideInFromRight}
                transition={{ duration: 1 }}
              >
                <img
                  src={illustratorImage}
                  alt="Illustrator Screenshot"
                  className="illustrator-image"
                />
              </motion.div>

              <article>
                I use Adobe Illustrator to create all of my ArtWork. I prefer
                using the latest Illustrator version, so that I can get
                performance boosts and the latest experience on that workplace.
                When using Adobe Illustrator, I use the built in macros
                they(illustrator) provide so I can manipulate the art board very
                easily. The trick is to getting know all the shortcuts you can
                use!
                <p>
                  Also what I need to mention is that I do not * use a drawing
                  pad when I'm Creating Art Works. I mainly use a mouse w/very
                  low DPI compared to the DPI that I use daily for other tasks,
                  because of that I primarily use the pen tool in the
                  Illustrator Application. Most people would choose to Draw with
                  the Brush Tool to get those fine curves. But I've mastered my
                  Mouse Movement so that I can basically move my mouse, exactly
                  how I want!
                </p>
              </article>
            </div>

            <div className="blog1-item item4">
              <h2>* Tools that I often use</h2>
            </div>

            <div className="blog1-item item4">
              <h2>
                <em>Pen Tool</em>
              </h2>
            </div>

            <div className="blog1-item item6">
              <motion.div
                className="tool-container-illustrator"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
                variants={slideInFromLeft}
                transition={{ duration: 1 }}
              >
                <PenSwing />
              </motion.div>

              <p>
                Pen Tool is my way-to Drawing tool, when it comes to Vector
                Arts. The pen allows you to draw lines in an angle of choise
                where a person can easily manipulate lines and get the shape
                they want. I know its a hassle to learn how to get the hang of
                it, but you can easily top your pen skills in no time.
              </p>
            </div>

            <div className="blog1-item item4">
              <h2>
                <em>Shape Tool</em>
              </h2>
            </div>

            <div className="blog1-item item6">
              <motion.div
                className="tool-container-illustrator"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
                variants={slideInFromRight}
                transition={{ duration: 1 }}
              >
                <ShapeTool />
              </motion.div>

              <p>
                I use the shape tool to get a hold of basic shapes like circles
                and rectangles. These act like a base when you are medling with
                more complex shapes. These base shapes can be customized using
                various tools, to Morph them / Warp them or Transform them.
              </p>
            </div>

            <div className="blog1-item item1">
              <h1>
                <Windmill /> Lets Make Art
              </h1>
            </div>

            <div className="blog1-item item66">
              <h2>* Choosing a Colour Palette</h2>
              <div className="circle-1"></div>
            </div>

            <motion.div
              className="blog1-item item3"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.1 }}
              variants={slideInFromLeft}
              transition={{ duration: 1 }}
            >
              <div className="color-pallette">
                <div className="circle-1"></div>
                <div className="circle-2"></div>
                <div className="circle-3"></div>
                <div className="circle-4"></div>
                <div className="circle-5"></div>
                <div className="circle-6"></div>
                <div className="circle-7"></div>
                <div className="circle-8"></div>
                <div className="circle-9"></div>
                <div className="circle-10"></div>
                <div className="circle-11"></div>
                <div className="circle-12"></div>
              </div>
            </motion.div>

            <div className="blog1-item item6">
              <article>
                Choosing a Color Palette is very crutial when it comes to Pro
                Art Creation. Firstly choose a color palette that ranges
                Brightest - to - Darkest. Make sure the two colors that you
                chose goes well with each other. That way when making the
                semi-transparent background, you wont get some weird discrepency
                in between layers.
                <p>
                  If you have no Idea how to choose a matching colour palette,
                  go to{" "}
                  <a
                    href="https://colormagic.app/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="unsplash-link"
                  >
                    Color Magic
                  </a>{" "}
                  and pick matching colours there!
                </p>
              </article>
            </div>

            <div className="blog1-item item66">
              <h2>* Gradient the Background</h2>
              <div className="circle-1"></div>
            </div>

            <div className="blog1-item item3">
              <div className="gradient-maker">
                <motion.div
                  className="circle1"
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.1 }}
                  variants={slideInFromLeft}
                  transition={{ duration: 1 }}
                ></motion.div>
                <motion.div
                  className="circle2"
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.1 }}
                  variants={slideInFromRight}
                  transition={{ duration: 1 }}
                ></motion.div>
                <motion.div
                  className="circle3"
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.1 }}
                  variants={slideInFromLeft}
                  transition={{ duration: 1 }}
                ></motion.div>
                <motion.div
                  className="circle4"
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.1 }}
                  variants={slideInFromRight}
                  transition={{ duration: 1 }}
                ></motion.div>
                <motion.div
                  className="circle5"
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.1 }}
                  variants={slideInFromLeft}
                  transition={{ duration: 1 }}
                ></motion.div>
                <motion.div
                  className="circle6"
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.1 }}
                  variants={slideInFromRight}
                  transition={{ duration: 1 }}
                ></motion.div>
              </div>
            </div>

            <div className="blog1-item item6">
              <article>
                You can witness that, choosing the right color palette makes
                this whole background looks so Kewl~!
                <p>
                  I used a Circular Gradient on all of the circles w/a smidge of
                  trasparency added to them. Important part is that you start
                  the lighter colors from the smallest circle and making your
                  way up darkning the circles, as the circles grow.
                </p>
              </article>
            </div>

            <div className="blog1-item item66">
              <h2>* Making the Object / Silhouette</h2>
              <div className="circle-1"></div>
            </div>

            <div className="blog1-item item6">
            <div key={key} className="layers-christmas">
    <AnimatePresence>
      <motion.img
        key={`layer1-${key}`}  // Unique key for layer 1
        src={layer1christmas}
        alt="Layer 1"
        className="layer-image1"
        initial="hidden"
        animate="visible"
        exit="hidden"
        variants={fadeVariant}
        transition={{ duration: 1, delay: 0 }}
        viewport={{ once: false, amount: 0.1 }}
      />
      <motion.img
        key={`layer2-${key}`}  // Unique key for layer 2
        src={layer2christmas}
        alt="Layer 2"
        className="layer-image2"
        initial="hidden"
        animate="visible"
        exit="hidden"
        variants={fadeVariant}
        transition={{ duration: 2, delay: 1 }}
        viewport={{ once: false, amount: 0.1 }}
      />
      <motion.img
        key={`layer3-${key}`}  // Unique key for layer 3
        src={layer3christmas}
        alt="Layer 3"
        className="layer-image3"
        initial="hidden"
        animate="visible"
        exit="hidden"
        variants={fadeVariant}
        transition={{ duration: 2, delay: 3 }}
        viewport={{ once: false, amount: 0.1 }}
      />
      <motion.img
        key={`layer4-${key}`}  // Unique key for layer 4
        src={layer4christmas}
        alt="Layer 4"
        className="layer-image4"
        initial="hidden"
        animate="visible"
        exit="hidden"
        variants={fadeVariant}
        transition={{ duration: 2, delay: 5 }}
        viewport={{ once: false, amount: 0.1 }}
      />
      <motion.img
        key={`layer5-${key}`}  // Unique key for layer 5
        src={layer5christmas}
        alt="Layer 5"
        className="layer-image5"
        initial="hidden"
        animate="visible"
        exit="hidden"
        variants={fadeVariant}
        transition={{ duration: 2, delay: 7 }}
        viewport={{ once: false, amount: 0.1 }}
      />
    </AnimatePresence>
  </div>

              <article>
                Now its time to create your object/s that match/es the
                background you created. You can either choose a silouhette or an
                actual object to draw and incoperate to your background.
                <p>
                  Also you can give Breaking the 4th Wall Effect by adding
                  Multiple subjects, peeking outside the background as seen in
                  this example!
                </p>
              </article>
            </div>

            <div className="blog1-item item3">
              <p>
                <em>
                  As the time goes & when you practise more and more, you'd be
                  able to spitout these kind of Artworks in no time. The key is
                  to Pick a matching Colour Palette and manipulate the subjects
                  inside that bubble more accurately. I would like to thank you
                  for reading my Blog on Pro Arts!
                </em>
              </p>
              <div className="ratings"></div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default Blog1;
