import React from "react";
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import "./Blog.css";

const Blog = () => {

  const blogBackgroundImage1 = 'url(https://res.cloudinary.com/dlnhogbjy/image/upload/v1738951615/922873132234681.61a52dd20a8ba_xceagh.jpg)';

  return (
    <section id="blog-section" className="blogs-main">
      <Helmet>
        <title>Nisal Herath - Blog</title>
        <meta name="description" content="Web site created by Nisal Herath, showcasing projects and portfolio." />
        <meta
          name="keywords"
          content="Nisal Herath, portfolio, web development, automation, React, Python, developer, SLIIT, Sri Lanka"
        />
        <meta name="author" content="Nisal Herath" />
        <meta
          property="og:title"
          content="Nisal Herath - Developer & Automation Specialist"
        />
        <meta
          property="og:description"
          content="Explore the personal website of Nisal Herath, a developer specializing in web applications and automation."
        />
        <meta
          property="og:image"
          content="https://web.facebook.com/photo?fbid=1576640353092218&set=a.112999039456364"
        />
        <meta property="og:url" content="https://nisal.lk" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Nisal Herath - Developer & Automation Specialist"
        />
        <meta
          name="twitter:description"
          content="Explore the personal website of Nisal Herath, a developer specializing in web applications and automation."
        />
        <meta name="twitter:image" content="https://x.com/ArtNisal/photo" />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <div className="blogtitlediv">
        <div className="blog-title">
          <motion.section
          initial={{ opacity: 0, y: -50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, amount: 0.1 }}
          transition={{ duration: 0.6 }}>
            <h1>My Blog</h1>
          </motion.section>
          


          <div className="blog-disclaimer">
            <p>
              All the blogs displayed in this Page are originally sourced from{" "}
              <a
                href="https://x.com/ArtNisal"
                target="_blank"
                rel="noopener noreferrer"
                className="blog-link"
              >
                Nisal Herath
              </a>
              , used under their license for free and commercial purposes with
              proper credit.
              <br />
              For concerns, feel free to contact me.
            </p>
          </div>

          <Link
            to="/"
            className="backhome3"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <div className="arrowhead2">
              <div className="arrowline12"></div>
              <div className="arrowline22"></div>
              <div className="arrowmainline2"></div>
            </div>
            Back Home
          </Link>
        </div>
      </div>













      <div className="blogs-items" >


            
        {/* Blog 1 - Clickable Link */}
        < div className="blogss">
          <Link to="/blog/1" className="blog-item-container">
            <div className="tags">
              <div className="blog-tag">Art</div>
            </div>
            <div className="blog-item" style={{ backgroundImage: blogBackgroundImage1 }} name="My Journey in Pro Arts" title="My Journey in Pro Arts">
              <p>Pro Art</p>
            </div>

          </Link>
        </div>

        {/* Blog 2 - Clickable Link */}
        < div className="blogss">
          <Link to="" className="blog-item-container">
            <div className="tags">
              <div className="blog-tag">Null</div>
            </div>
            <div className="blog-item">
            <h2>More Comming Soon</h2>
            </div>
          </Link>
        </div>

        {/* Blog 3 - Clickable Link */}
        < div className="blogss">
          <Link to="" className="blog-item-container">
            <div className="tags">
              <div className="blog-tag">Null</div>
            </div>
            <div className="blog-item">
            <h2>More Comming Soon</h2>
            </div>
          </Link>
        </div>
      </div>

      <Footer />
    </section>
  );
};

export default Blog;
